import { users } from "../../constants/actionTypes";

const initialState = {
  selected_user: null,
  all_users: null,
  active_users: null,
  total_investment: null,
  total_investors: null,
};

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case users.SELECTED_USER:
      return { ...state, selected_user: action.payload };
    case users.ALL_USERS:
      return { ...state, all_users: action.payload };
    case users.ACTIVE_USERS:
      return { ...state, active_users: action.payload };
    case users.TOTAL_INVESTOMENT:
      return { ...state, total_investment: action.payload };
    case users.TOTAL_INVESTORS:
      return { ...state, total_investors: action.payload };
    default:
      return state;
  }
}
export default usersReducer;
