import React from "react";
import logo from "../../assets/icons/DeepReef-Logo.png";

export default function Login({ onClickLogin, onChange }) {
  return (
    <div className="container" id="registration-page">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-md-offset-4">
          <h1 className="text-center login-title">
            <img src={logo} height="60" width="140" />
          </h1>
          <div className="account-wall">
            <div className="form-signin">
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  autoComplete="off"
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => onChange(e)}
                  className="form-control"
                  id="email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  autoComplete="off"
                  type="password"
                  placeholder="Enter password"
                  onChange={(e) => onChange(e)}
                  className="form-control"
                  id="password"
                />
              </div>
              <button
                className="btn btn-primary btn-block"
                onClick={() => onClickLogin()}
              >
                Log In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
