import React from "react";
import { Line } from "react-chartjs-2";

function _tick(value, index, values) {
  return value;
}

export default function Balance({ data, height, width }) {
  let lineLegend = { display: false };
  const options = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            callback: _tick,
          },
        },
      ],
    },
  };
  return (
    <Line
      id="dashboard-chart"
      height={height}
      width={width}
      data={data ? data : []}
      options={options}
      legend={lineLegend}
    />
  );
}
