import React from "react";
import { Link } from "react-router-dom";

//icons
import logo from "../../assets/icons/DeepReef-Logo.png";
import dashboard from "../../assets/icons/dashboard_icon.png";
import transactions from "../../assets/icons/transactions_icon.png";
import withDrawal from "../../assets/icons/withdrawal_icon.png";
import user from "../../assets/icons/user_profile_icon.png";
import logout from "../../assets/icons/logout_icon.png";

export default function Sidebar({ nav, onChangeNav }) {
  return (
    <>
      <a id="show-sidebar" className="btn btn-lg">
        <i className="fas fa-bars"></i>
      </a>
      <nav id="sidebar" className="sidebar-wrapper">
        <div className="sidebar-content">
          <div className="sidebar-brand">
            <a href="/dashboard">
              <img src={logo} height="30" width="80" />
            </a>
            <div id="close-sidebar">
              <i className="fas fa-times"></i>
            </div>
          </div>
          <div className="sidebar-menu">
            <ul>
              <li className="header-menu" style={{ height: "30px" }}></li>
              <li className={nav == "/dashboard" ? "sidenav-active" : ""}>
                <Link to="/dashboard" onClick={() => onChangeNav("/dashboard")}>
                  <img src={dashboard} />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className={nav == "/transactions" ? "sidenav-active" : ""}>
                <Link
                  to="/transactions"
                  onClick={() => onChangeNav("/transactions")}
                >
                  <img src={transactions} />
                  <span>Transactions</span>
                </Link>
              </li>
              <li className={nav == "/withdrawal" ? "sidenav-active" : ""}>
                <Link
                  to="/withdrawal"
                  onClick={() => onChangeNav("/withdrawal")}
                >
                  <img src={withDrawal} />
                  <span>WithDrawal</span>
                </Link>
              </li>
              <li className={nav == "/investors" ? "sidenav-active" : ""}>
                <Link to="/investors" onClick={() => onChangeNav("/investors")}>
                  <img src={user} />
                  <span>Investors</span>
                </Link>
              </li>
              <li className={nav == "/rounds" ? "sidenav-active" : ""}>
                <Link to="/rounds" onClick={() => onChangeNav("/rounds")}>
                  <img src={withDrawal} />
                  <span>Investment Rounds</span>
                </Link>
              </li>
              {/* <li className={nav == "/upcoming-rounds" ? "sidenav-active" : ""}>
                <Link
                  to="/upcoming-rounds"
                  onClick={() => onChangeNav("/upcoming-rounds")}
                >
                  <img src={withDrawal} />
                  <span>Upcoming Investments</span>
                </Link>
              </li> */}
              <li className={nav == "/" ? "sidenav-active" : ""}>
                <Link to="/" onClick={() => onChangeNav("/")}>
                  <img src={logout} />
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
