import { makeStyles } from "@material-ui/core/styles";

export const tableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#1c1c24",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "#1c1c24",
    marginTop: "10px",
  },
  // body: {
  //   color: "#fff",
  // },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
