import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import { goto } from "../helpers";

import Routes from "../routes";
import Sidebar from "../containers/Sidebar";
import Header from "../containers/AppHeader";
import Login from "../containers/Login";
import Loader from "../components/Common/Loader";

class MainApp extends Component {
  componentDidMount() {
    const { userInfo } = this.props;
    if (!userInfo) {
      goto("/");
    }
  }
  render() {
    return (
      <>
        <Header />
        <div className="page-wrapper chiller-theme">
          <Sidebar />
          <div className="page-content">
            <Routes />
          </div>
        </div>
      </>
    );
  }
}

class App extends Component {
  render() {
    const pathname = window.location.pathname;
    const { isLoading, userInfo } = this.props;
    let pointerEvents = isLoading ? "none" : "";
    let opacity = isLoading ? 0.5 : 1;

    return (
      <BrowserRouter>
        <Loader display={isLoading} />
        <div style={{ pointerEvents, opacity }}>
          {pathname === "/" ? <Login /> : <MainApp userInfo={userInfo} />}
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.common.isLoading,
    userInfo: state.auth.login_user_info,
  };
};

export default connect(mapStateToProps, {})(App);
