import React from "react";

const Panel = (props) => {
  return (
    <div
      className="card"
      style={{ padding: props.padding ? props.padding : "15px 20px 15px 20px" }}
    >
      {props.children}
    </div>
  );
};

export default Panel;
