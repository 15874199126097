import React from "react";
import moment from "moment";

export default function UpcomingInvestments({ startedInvestments, goto }) {
  return (
    <div className="dashboard-card-2">
      <div className="table-responsive">
        <div className="transaction-data">
          <table className="table">
            <thead>
              <tr>
                <td>INVESTOR</td>
                <td>START DATE</td>
                <td>REMAINING DAYS</td>
                <td>STATUS</td>
              </tr>
            </thead>
            <tbody>
              {startedInvestments
                ? startedInvestments.map((round, index) =>
                    index <= 4 ? (
                      <tr key={round.id}>
                        <td>{round.investor_name}</td>
                        <td>{moment(round.start_date).format("MM/DD/YYYY")}</td>

                        <td style={{ textAlign: "center" }}>
                          {round.remainingDays}
                        </td>
                        <td>
                          {round.status ? (
                            <span
                              className="status"
                              style={{
                                color:
                                  round.status === "Completed"
                                    ? "#27AE60"
                                    : round.status === "Started"
                                    ? "#1F618D"
                                    : "#FFFF00",
                                backgroundColor:
                                  round.status === "Completed"
                                    ? "rgb(34, 153, 84, 0.2)"
                                    : round.status === "Started"
                                    ? "rgb(31, 97, 141, 0.2)"
                                    : "rgb(255,255,0, 0.2)",
                              }}
                            >
                              {round.status}
                            </span>
                          ) : null}
                        </td>
                      </tr>
                    ) : null
                  )
                : null}
            </tbody>
          </table>
          {startedInvestments && startedInvestments.length > 0 ? null : (
            <div className="no-data">
              <i>No data found...</i>
            </div>
          )}
        </div>
      </div>
      <hr style={{ marginTop: "4px" }} />
      <div className="view-investors" onClick={() => goto("/rounds")}>
        VIEW ALL INVESTMENTS
      </div>
    </div>
  );
}
