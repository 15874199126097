import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { tableStyles } from "../Common/StyleComponents";
import moment from "moment";
import { isEmptyOrNull, addCommas } from "../../helpers";

const headCells = [
  {
    id: "transaction_id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "DATE",
  },
  {
    id: "investorName",
    numeric: false,
    disablePadding: false,
    label: "INVESTOR",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "AMOUNT",
  },
  {
    id: "investorBalance",
    numeric: false,
    disablePadding: false,
    label: "BALANCE",
  },
  { id: "type", numeric: false, disablePadding: false, label: "TYPE" },
  {
    id: "autorizedBy",
    numeric: false,
    disablePadding: false,
    label: "AUTORISED",
  },
];

function EnhancedTransactionsTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTransactionsTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTransactionsTable({
  rows,
  selected,
  setSelected,
  onFilterTextChange,
  onDelete,
  action,
}) {
  const classes = tableStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.transaction_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Paper className={classes.paper}>
      <div className="row" style={{ padding: "15px 15px 0 15px" }}>
        <div className="col-sm-10 col-xs-9">
          <div className="form-group">
            <input
              style={{ backgroundColor: "#1c1c24" }}
              type="text"
              placeholder="Search Transactions"
              className="form-control"
              id="search-transactions"
              onChange={(e) => onFilterTextChange(e)}
            />
            {/* <div className="icon-addon addon-md">
              <input
                style={{ backgroundColor: "#1c1c24" }}
                type="text"
                placeholder="Search Transaction"
                className="form-control"
                id="search-transactions"
                onChange={(e) => onFilterTextChange(e)}
              />
              <label
                htmlFor="search-transactions"
                className="glyphicon glyphicon-search"
                rel="Search Transaction"
                title="Search Transaction"
              ></label>
            </div> */}
          </div>
        </div>
        <div className="col-sm-2 col-xs-3">
          <select
            className="form-control"
            style={{ backgroundColor: "#1c1c24" }}
            value={action ? action : "Actions"}
            onChange={(e) => onDelete(e)}
          >
            <option disabled>Actions</option>
            <option>Delete</option>
          </select>
        </div>
      </div>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedTransactionsTableHead
            classes={classes}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows ? rows.length : 0}
          />
          <TableBody>
            {rows
              ? rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.transaction_id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.transaction_id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) =>
                              handleClick(event, row.transaction_id)
                            }
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          style={{ color: "gray" }}
                        >
                          {row.transaction_id}
                        </TableCell>
                        <TableCell align="left" style={{ color: "gray" }}>
                          {row.date_time}
                        </TableCell>
                        <TableCell style={{ color: "gray" }}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left" style={{ color: "gray" }}>
                          {row.amount}
                        </TableCell>
                        <TableCell style={{ color: "gray" }}>
                          {row.balance}
                        </TableCell>
                        <TableCell align="left">
                          {row.transaction_type ? (
                            <span
                              className="status"
                              style={{
                                color:
                                  row.transaction_type === "Deposit"
                                    ? "#27AE60"
                                    : row.transaction_type === "Withdrawal"
                                    ? "#C0392B"
                                    : "#1F618D",
                                backgroundColor:
                                  row.transaction_type === "Deposit"
                                    ? "rgb(34, 153, 84, 0.2)"
                                    : row.transaction_type === "Withdrawal"
                                    ? "rgb(169, 50, 38, 0.2)"
                                    : "rgb(31, 97, 141, 0.2)",
                              }}
                            >
                              {row.transaction_type}
                            </span>
                          ) : null}
                        </TableCell>
                        <TableCell align="left" style={{ color: "gray" }}>
                          {row.autorised}
                        </TableCell>
                      </TableRow>
                    );
                  })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={rows ? rows.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
