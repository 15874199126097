import { USER_API } from "../../api";
import { loggedUser } from "../../constants/actionTypes";
import { loaderState, selectedNav } from "../common";
import swal from "sweetalert";
import { goto } from "../../helpers";

export const signIn = (email, password) => (dispatch) => {
  let filter = {
    where: {
      email,
      password,
    },
  };
  USER_API.get(`/managerDetails?filter=${JSON.stringify(filter)}`)
    .then((res) => {
      if (typeof res.data !== "string") {
        if (res.data.length > 0) {
          dispatch({ type: loggedUser.UERS_INFO, payload: res.data[0] });
          setTimeout(() => {
            dispatch(selectedNav("/dashboard"));
            goto("/dashboard");
          }, 500);
        } else {
          swal("Oops!", "Invalid email address or password", "error");
        }
      } else {
        swal("Oops!", res.data, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const resetPassword = (data) => (dispatch) => {
  USER_API.post(`/managerDetails/resetPassword`, data)
    .then((res) => {
      dispatch(loaderState(false));
      swal("", "Password reset successfully", "success");
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};
