import React from "react";
import { Route, Switch } from "react-router-dom";

//containers
import Dashboard from "../containers/Dashboard";
import Users from "../containers/Users";
import Transactions from "../containers/Transactions";
import WithDrawal from "../containers/WithDrawal";
import InvestmentRound from "../containers/InvestmentRounds";
import UpcomingRounds from "../containers/UpcomingRounds";

const Routes = () => {
  return (
    <Switch>
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/investors" component={Users} />
      <Route path="/transactions" component={Transactions} />
      <Route path="/withdrawal" component={WithDrawal} />
      <Route path="/rounds" component={InvestmentRound} />
      <Route path="/upcoming-rounds" component={UpcomingRounds} />
    </Switch>
  );
};

export default Routes;
