import _ from "lodash";
import history from "../history";
import moment from "moment";
import { managers } from "../constants/actionTypes";

export const isEmptyOrNull = (data) => {
	let valid = false;
	if (data == null || data === "") {
		valid = true;
	}
	return valid;
};
export const isNullOrUndefined = (data) => {
	return data != undefined && data !== null;
};
export const removeNullValues = (object) => {
	let newObj = {};
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			if (!isEmptyOrNull(object[key])) {
				newObj[key] = object[key];
			}
		}
	}
	return newObj;
};

export const getProgress = (balance, target) => {
	let progress = 0;
	if (Number(balance) !== 0 && Number(target) !== 0) {
		progress = Math.ceil((Number(balance) / Number(target)) * 100);
	}
	if (target === 0 && balance > 0) {
		progress = 100;
	}
	if (progress > 100) {
		progress = 100;
	}
	return progress;
};

export const currenciesData = (countriesData) => {
	let data = [];
	_.forEach(countriesData, (country) => {
		data.push(country.currencies[0].code);
	});

	return data;
};

export const getCountries = (countriesData) => {
	let data = [];
	_.forEach(countriesData, (country) => {
		data.push(country.name);
	});

	return data;
};

export const initilizeDataTable = (id) => {
	window.$(`#${id}`).DataTable({
		// ordering: true,
		// select: true,
		// searching: true,
		pagingType: "full_numbers",
		language: {
			paginate: {
				next: "&rsaquo;",
				previous: "&lsaquo;",
				first: "&laquo;",
				last: "&raquo;",
			},
		},
		sDom: '<"row view-filter"<"col-sm-12"<"pull-left"l><"pull-right"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
	});
};

export const destroyDataTable = (id) => {
	window.$(`#${id}`).DataTable().destroy();
};

export const goto = (path) => {
	history.push(path);
	history.go(path);
};

export const addCommas = (x) => {
	let number = "0";
	if (x) {
		number = x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}
	if (!number.includes(".")) {
		number = number + ".00";
	}
	return number;
};

export const mergeArrayObjects = (arr1, arr2, col, obj) => {
	const data = arr1.map((item) => {
		const result = arr2.find((o) => o[col] === item[col]);
		return obj ? { ...item, [obj]: result } : { ...item, ...result };
	});
	return data;
};

export const getRandomColor = () => {
	var letters = "0123456789ABCDEF";
	var color = "#";
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

export const getRandomNumberArray = (length, upto) => {
	return Array.from({ length: Number(length) }, () => Math.floor(Math.random() * Number(upto)));
};

export const userTier = (balance) => {
	let tiers = ["Bronze", "Silver", "Gold", "Platinum", "Black"];
	let tier = null;
	let bal = Number(balance);
	if (bal >= 1000 && bal <= 10000) {
		tier = tiers[0];
	} else if (bal > 10000 && bal <= 50000) {
		tier = tiers[1];
	} else if (bal > 50000 && bal <= 150000) {
		tier = tiers[2];
	} else if (bal > 150000 && bal <= 500000) {
		tier = tiers[3];
	} else if (bal > 500000) {
		tier = tiers[4];
	}
	return tier;
};

export const createTransactionsData = (data) => {
	let transactionsData = [];
	_.forEach(data, (d) => {
		let name = d.userData ? d.userData.first_name : "";
		name = name.concat(" ", d.userData ? d.userData.last_name : "");
		name = name.concat(" ", d.userData ? " (" + d.userData.account_number + ")" : "");

		transactionsData.push({
			transaction_id: d.transaction_id,
			date_time: !isEmptyOrNull(d.date_time) ? moment(d.date_time).format("LLL") : "",
			amount: !isEmptyOrNull(d.amount) ? getCurrencySymbol(d.currency) + addCommas(d.amount) : getCurrencySymbol(d.currency) + "0",
			name: name,
			balance: !isEmptyOrNull(d.balance) ? getCurrencySymbol(d.currency) + addCommas(d.new_balance) : getCurrencySymbol(d.currency) + "0",
			transaction_type: d.transaction_type,
			autorised: "User",
		});
	});
	return transactionsData;
};

export const createWithdrawalsData = (data) => {
	let withdrawalsData = [];
	_.forEach(data, (d) => {
		let name = d.userData ? d.userData.first_name : "";
		name = name.concat(" ", d.userData ? d.userData.last_name : "");
		name = name.concat(" ", d.userData ? " (" + d.userData.account_number + ")" : "");

		withdrawalsData.push({
			transaction_id: d.transaction_id,
			date_time: !isEmptyOrNull(d.date_time) ? moment(d.date_time).format("LLL") : "",
			name: name,
			amount: !isEmptyOrNull(d.amount) ? getCurrencySymbol(d.currency) + addCommas(d.amount) : getCurrencySymbol(d.currency) + "0",
			currency: d.currency,
			status: d.status,
			withdrawl_type: d.withdrawl_type,
			bankDetails: d.bankDetails,
		});
	});
	return withdrawalsData;
};

export const filterData = (arr, text) => {
	let filteredData = [];
	let searchtext = text.toLowerCase();
	_.forEach(arr, (object) => {
		let exist = false;
		for (let key in object) {
			if (object.hasOwnProperty(key)) {
				let value = object[key];
				if (!isEmptyOrNull(value)) {
					value = value.toString().toLowerCase().replace(",", "");
					if (value.includes(searchtext)) {
						exist = true;
					}
				}
			}
		}
		if (exist) {
			filteredData.push(object);
		}
	});

	return filteredData;
};

export const investorsChartData = (investors) => {
	let data = [];
	let labels = [];
	let backgroundColor = [];
	for (var i = 0; i < investors.length; i++) {
		let r = Math.floor(Math.random() * 200);
		let g = Math.floor(Math.random() * 200);
		let b = Math.floor(Math.random() * 200);
		let c = "rgb(" + r + ", " + g + ", " + b + ")";
		if (investors[i].balance && investors[i].balance > 0) {
			labels.push(investors[i].first_name + " " + investors[i].last_name + " " + getCurrencySymbol(investors[i].currency));
			data.push(investors[i].balance);
			backgroundColor.push(c);
		}
	}
	let finalData = {
		labels,
		datasets: [{ data, backgroundColor, borderWidth: 0 }],
	};

	return finalData;
};

export const getMonthYearString = () => {
	let year = moment().format("YYYY");
	let month = moment().format("MMMM");

	return month + "_" + year;
};

const sumByKey = (data) => {
	var finalResult = _(data)
		.groupBy("month_year")
		.map((objs, key) => ({
			[key]: Number(_.sumBy(objs, "amount")).toFixed(2),
		}))
		.value();

	return finalResult;
};

export const getDashboardBalanceGraphData = (transactions) => {
	let balance = [];

	_.forEach(transactions, (transaction) => {
		if (transaction.status === "Completed") {
			let data = {
				month_year: transaction.month_year,
				type: transaction.transaction_type,
			};
			if (transaction.transaction_type === "Withdrawal") {
				data.amount = -transaction.amount;
			} else {
				data.amount = transaction.amount;
			}
			balance.push({
				...data,
			});
		}
	});

	balance = sumByKey(balance, "month_year", "amount");

	let graphLabels = [];
	_.forEach(balance, (label) => {
		for (let key in label) {
			if (label.hasOwnProperty(key)) {
				if (!isEmptyOrNull(label[key])) {
					graphLabels.push(key);
				}
			}
		}
	});

	let balanceData = [graphLabels.length];

	for (let i = 0; i < graphLabels.length; i++) {
		let match = false;
		_.forEach(balance, (bal) => {
			Object.keys(bal).forEach(function (key) {
				if (key === graphLabels[i]) {
					balanceData[i] = bal[key];
					match = true;
				}
			});
		});

		if (!match) {
			balanceData[i] = 0;
		}
	}

	return {
		graphLabels,
		balanceData,
	};
};

export const getDashboardCurrenciesData = (transactions) => {
	let GBP = [];
	let LIRA = [];
	let USD = [];
	let KZT = [];
	let AED = [];
	let RUB = [];
	let EUR = [];
	let forLabels = [];
	let largestArray = "";
	let largestArraySize = 0;
	_.forEach(transactions, (transaction) => {
		if (transaction.status === "Completed") {
			let data = {
				month_year: transaction.month_year,
				type: transaction.transaction_type,
			};
			if (transaction.transaction_type === "Withdrawal") {
				data.amount = -transaction.amount;
			} else {
				data.amount = transaction.amount;
			}
			if (transaction.userData) {
				if (transaction.userData.currency && transaction.userData.currency === "GBP") {
					GBP.push({
						...data,
					});
					if (GBP.length > largestArraySize) {
						largestArraySize = GBP.length;
						largestArray = GBP;
					}
				} else if (transaction.userData.currency && transaction.userData.currency === "LIRA") {
					LIRA.push({
						...data,
					});
					if (LIRA.length > largestArraySize) {
						largestArraySize = LIRA.length;
						largestArray = LIRA;
					}
				} else if (transaction.userData.currency && transaction.userData.currency === "KZT") {
					KZT.push({
						...data,
					});
					if (KZT.length > largestArraySize) {
						largestArraySize = KZT.length;
						largestArray = KZT;
					}
				} else if (transaction.userData.currency && transaction.userData.currency === "AED") {
					AED.push({
						...data,
					});
					if (AED.length > largestArraySize) {
						largestArraySize = AED.length;
						largestArray = AED;
					}
				} else if (transaction.userData.currency && transaction.userData.currency === "EUR") {
					EUR.push({
						...data,
					});
					if (EUR.length > largestArraySize) {
						largestArraySize = EUR.length;
						largestArray = EUR;
					}
				} else if (transaction.userData.currency && transaction.userData.currency === "RUB") {
					RUB.push({
						...data,
					});
					if (RUB.length > largestArraySize) {
						largestArraySize = RUB.length;
						largestArray = RUB;
					}
				} else if (transaction.userData.currency && transaction.userData.currency === "USD") {
					USD.push({
						...data,
					});
					if (USD.length > largestArraySize) {
						largestArraySize = USD.length;
						largestArray = USD;
					}
				}
			}
			forLabels.push({
				...data,
			});
		}
	});
	GBP = sumByKey(GBP, "month_year", "amount");
	LIRA = sumByKey(LIRA, "month_year", "amount");
	USD = sumByKey(USD, "month_year", "amount");
	KZT = sumByKey(KZT, "month_year", "amount");
	AED = sumByKey(AED, "month_year", "amount");
	RUB = sumByKey(RUB, "month_year", "amount");
	EUR = sumByKey(EUR, "month_year", "amount");
	largestArray = sumByKey(largestArray, "month_year", "amount");

	let graphLabels = [];
	_.forEach(largestArray, (label) => {
		for (let key in label) {
			if (label.hasOwnProperty(key)) {
				if (!isEmptyOrNull(label[key])) {
					graphLabels.push(key);
				}
			}
		}
	});

	let GBPData = [graphLabels.length];
	let LIRAData = [graphLabels.length];
	let USDData = [graphLabels.length];
	let KZTData = [graphLabels.length];
	let AEDData = [graphLabels.length];
	let RUBData = [graphLabels.length];
	let EURData = [graphLabels.length];

	for (let i = 0; i < graphLabels.length; i++) {
		let GBPMatch = false;
		let LIRAMatch = false;
		let USDMatch = false;
		let KZTMatch = false;
		let AEDMatch = false;
		let RUBMatch = false;
		let EURMatch = false;
		_.forEach(GBP, (uk) => {
			Object.keys(uk).forEach(function (key) {
				if (key === graphLabels[i]) {
					GBPData[i] = uk[key];
					GBPMatch = true;
				}
			});
		});
		_.forEach(LIRA, (turkey) => {
			Object.keys(turkey).forEach(function (key) {
				if (key === graphLabels[i]) {
					LIRAData[i] = turkey[key];
					LIRAMatch = true;
				}
			});
		});
		_.forEach(USD, (us) => {
			Object.keys(us).forEach(function (key) {
				if (key === graphLabels[i]) {
					USDData[i] = us[key];
					USDMatch = true;
				}
			});
		});
		_.forEach(KZT, (kzk) => {
			Object.keys(kzk).forEach(function (key) {
				if (key === graphLabels[i]) {
					KZTData[i] = kzk[key];
					KZTMatch = true;
				}
			});
		});
		_.forEach(AED, (dub) => {
			Object.keys(dub).forEach(function (key) {
				if (key === graphLabels[i]) {
					AEDData[i] = dub[key];
					AEDMatch = true;
				}
			});
		});
		_.forEach(RUB, (rus) => {
			Object.keys(rus).forEach(function (key) {
				if (key === graphLabels[i]) {
					RUBData[i] = rus[key];
					RUBMatch = true;
				}
			});
		});
		_.forEach(EUR, (fr) => {
			Object.keys(fr).forEach(function (key) {
				if (key === graphLabels[i]) {
					EURData[i] = fr[key];
					EURMatch = true;
				}
			});
		});
		if (!GBPMatch) {
			GBPData[i] = 0;
		}
		if (!LIRAMatch) {
			LIRAData[i] = 0;
		}
		if (!USDMatch) {
			USDData[i] = 0;
		}
		if (!KZTMatch) {
			KZTData[i] = 0;
		}
		if (!AEDMatch) {
			AEDData[i] = 0;
		}
		if (!RUBMatch) {
			RUBData[i] = 0;
		}
		if (!EURMatch) {
			EURData[i] = 0;
		}
	}

	return {
		graphLabels,
		GBPData,
		LIRAData,
		USDData,
		KZTData,
		AEDData,
		RUBData,
		EURData,
	};
};

export const managersProfitChartData = (manager) => {
	let labels = ["USD", "EUR", "LIRA", "AED", "KZT", "RUB", "GBP"];
	let data = [
		manager.profit_dollar,
		manager.profit_euro,
		manager.profit_lira,
		manager.profit_dirham,
		manager.profit_tenge,
		manager.profit_ruble,
		manager.profit_pound,
	];
	let datasets = [
		{
			// label: ,
			data,
			backgroundColor: [
				"rgba(235, 99, 132, 0.2)",
				"rgba(54, 162, 235, 0.2)",
				"rgba(245, 206, 86, 0.2)",
				"rgba(75, 192, 192, 0.2)",
				"rgba(153, 102, 255, 0.2)",
				"rgba(223, 19, 64, 0.2)",
				"rgba(250, 99, 132, 0.2)",
			],
		},
	];
	let finalData = {
		labels,
		datasets,
	};

	return finalData;
};

export function getCurrencySymbol(currency) {
	//GBP, LIRA, AED, RUB, EUR, KZT, USD
	let symbol = "";
	if (currency === "GBP" || currency === "GB") {
		symbol = "£";
	} else if (currency === "LIRA") {
		symbol = "₺";
	} else if (currency === "AED") {
		symbol = "د.إ";
	} else if (currency === "RUB") {
		symbol = "₽";
	} else if (currency === "EUR") {
		symbol = "€";
	} else if (currency === "KZT") {
		symbol = "₸";
	} else if (currency === "USD") {
		symbol = "$";
	}
	return symbol;
}

export const createRoundsData = (data) => {
	let roundsData = [];
	_.forEach(data, (d) => {
		let startDate = d.start_date ? moment(d.start_date).format("YYYY-MM") : null;
		let startD = d.start_date ? moment(new Date(d.start_date), "DD.MM.YYYY") : null;
		let c_date = moment(new Date(), "DD.MM.YYYY");
		let endDate = startD ? moment(c_date, "DD.MM.YYYY") : null;

		let remainingDays = startD ? 30 - endDate.diff(startD, "days") : 30;
		let progress = startD ? getProgress(endDate.diff(startD, "days"), moment(startDate, "YYYY-MM").daysInMonth()) : 0;

		roundsData.push({
			id: d.id,
			round_name: d.round_name,
			account_number: d.acount_number,
			investor_name: d.first_name + " " + d.last_name,
			start_date: !isEmptyOrNull(d.start_date) ? moment(d.start_date).format("LLL") : "",
			complete_date: !isEmptyOrNull(d.complete_date) ? moment(d.complete_date).format("LLL") : "",
			status: d.status,
			authorized_by: d.authorized_by,
			notes: d.notes,
			progress: d.status === "Completed" ? 100 : progress,
			remainingDays: d.status === "Pending" ? 31 : Number(remainingDays),
		});
	});

	return roundsData;
};

export function getUpcomingInvestment(rounds) {
	let upcomingRounds = [];

	_.forEach(rounds, (round) => {
		if (round.start_date && round.status === "Started") {
			let currentDate = moment(new Date(), "DD.MM.YYYY");
			let startDate = moment(moment(round.start_date), "DD.MM.YYYY");
			let diff = currentDate.diff(startDate, "days");
			if (diff >= 27) {
				upcomingRounds.push(round);
			}
		}
	});

	return upcomingRounds;
}
