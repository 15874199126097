import React from "react";

export default function Tabs({ tabs, link, className }) {
  return (
    <ul
      className={`nav nav-tabs ${className ? className : ""}`}
      role="tablist"
      id="comp-tab"
    >
      {tabs.map((tab, index) => {
        return (
          <li className={`nav-item ${index === 0 ? "active" : ""}`} key={tab}>
            <a
              className="nav-link"
              data-toggle="tab"
              href={`#${tab.split(" ").join("")}`}
            >
              {tab}
            </a>
          </li>
        );
      })}
      {link ? (
        <li className="nav-item" id="last-link">
          <a className="nav-link">{link}</a>
        </li>
      ) : null}
    </ul>
  );
}
