import { managers } from "../../constants/actionTypes";

const initialState = {
  all_managers: null,
  top_managers: null,
  selected_manager: null,
};

function managersReducer(state = initialState, action) {
  switch (action.type) {
    case managers.ALL_MANAGERS:
      return { ...state, all_managers: action.payload };
    case managers.TOP_MANAGERS:
      return { ...state, top_managers: action.payload };
    case managers.SELECTED_MANAGER:
      return { ...state, selected_manager: action.payload };
    default:
      return state;
  }
}
export default managersReducer;
