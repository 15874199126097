import { rounds } from "../../constants/actionTypes";
import { USER_API } from "../../api";
import _ from "lodash";
import { loaderState } from "../common";
import swal from "sweetalert";

export const getRounds = () => (dispatch, getState) => {
  const { login_user_info } = getState().auth;
  let users_ids = [];
  _.forEach(login_user_info.investors, (investor) => {
    users_ids.push(investor.account_number);
  });

  let filter = {
    where: {
      acount_number: { inq: { ...users_ids } },
    },
    order: "start_date DESC",
  };
  USER_API.get(`/InvestmentRounds?filter=${JSON.stringify(filter)}`)
    .then((res) => {
      dispatch(loaderState(false));
      console.log(res.data);
      let newRounds = _.orderBy(res.data, ["start_date"], ["desc"]);
      dispatch({ type: rounds.ROUNDS, payload: newRounds });
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};
