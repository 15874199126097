import { transactions } from "../../constants/actionTypes";

const initialState = {
  transactions: null,
  withDrawals: null,
  transactions_count: 0,
  withDrawals_count: 0,
  dashboard_transactions: null,
  selected_withdrawal: null,
};

function transactionsReducer(state = initialState, action) {
  switch (action.type) {
    case transactions.ALL_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case transactions.TRANSACTIONS_COUNT:
      return { ...state, transactions_count: action.payload };
    case transactions.WITHDRAWALS:
      return { ...state, withDrawals: action.payload };
    case transactions.WITHDRAWALS_COUNT:
      return { ...state, withDrawals_count: action.payload };
    case transactions.DASHBOARD_TRANSACTIONS:
      return { ...state, dashboard_transactions: action.payload };
    case transactions.SELECTED_WITHDRAWAL:
      return { ...state, selected_withdrawal: action.payload };
    default:
      return state;
  }
}
export default transactionsReducer;
