import { rounds } from "../../constants/actionTypes";

const initialState = {
  all_rounds: null,
};

function roundsReducer(state = initialState, action) {
  switch (action.type) {
    case rounds.ROUNDS:
      return { ...state, all_rounds: action.payload };
    default:
      return state;
  }
}
export default roundsReducer;
