import { USER_API } from "../../api";
import { users, loggedUser } from "../../constants/actionTypes";
import swal from "sweetalert";
import { loaderState } from "../index";
import { mergeArrayObjects } from "../../helpers";
import _ from "lodash";

export const getActiveUsers = () => (dispatch, getState) => {
  const { login_user_info } = getState().auth;
  let filter = {
    where: {
      status: "Active",
      manager_id: login_user_info.id,
    },
    order: "first_name ASC",
  };
  USER_API.get(`/ClientRegistrations?filter=${JSON.stringify(filter)}`)
    .then((res) => {
      let data = res.data;
      if (typeof data !== "string") {
        dispatch({ type: users.ACTIVE_USERS, payload: res.data });
        dispatch(loaderState(false));
      } else {
        dispatch(loaderState(false));
        swal("Oops!", data, "error");
      }
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const getUsers = () => (dispatch, getState) => {
  const { login_user_info } = getState().auth;

  let filter = {
    where: {
      manager_id: login_user_info.id,
    },
    order: "first_name ASC",
  };
  USER_API.get(`/ClientRegistrations?filter=${JSON.stringify(filter)}`)
    .then((res) => {
      let data = res.data;
      if (typeof data !== "string") {
        dispatch({
          type: loggedUser.UERS_INFO,
          payload: { ...login_user_info, investors: res.data },
        });
        dispatch({ type: users.ALL_USERS, payload: res.data });
        dispatch(loaderState(false));
      } else {
        dispatch(loaderState(false));
        swal("Oops!", data, "error");
      }
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const selectedUser = (user) => {
  return { type: users.SELECTED_USER, payload: user };
};

export const getTotalInvestments = () => (dispatch, getState) => {
  const { login_user_info } = getState().auth;
  let filter = {
    fields: {
      id: false,
      tier: false,
      account_number: false,
      manager_id: false,
    },
    where: {
      manager_id: login_user_info.id,
    },
  };
  USER_API.get(`/ClientRegistrations?filter=${JSON.stringify(filter)}`)
    .then((res) => {
      dispatch({
        type: users.TOTAL_INVESTOMENT,
        payload: _.sumBy(res.data, "balance"),
      });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("Oops!", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateUser = (ids, data) => (dispatch) => {
  let where = {
    id: { inq: { ...ids } },
  };
  USER_API.patch(`/ClientRegistrations?where=${JSON.stringify(where)}`, {
    ...data,
  })
    .then((res) => {
      dispatch(getUsers());
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const getUsersBankDetails = (account_numbers, data, type) => (
  dispatch
) => {
  let where = {
    inq: account_numbers,
  };
  USER_API.get(`/ClientBankingdetailsDomestic?where=${JSON.stringify(where)}`)
    .then((res) => {
      let mergedData = mergeArrayObjects(
        data,
        res.data,
        "account_number",
        "bankDetails"
      );
      dispatch({ type, payload: mergedData });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("Oops!", err.message, "error");
    });
};

export const addInvestor = (data) => (dispatch, getState) => {
  const { login_user_info } = getState().auth;
  data.manager_id = login_user_info.id;

  USER_API.post("/ClientRegistrations/signup", data)
    .then((res) => {
      dispatch(getUsers());
      swal("", "Investor added", "success");
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const updateInvestor = (id, data) => (dispatch, getState) => {
  const { all_managers } = getState().managers;
  if (data.manager_id) {
    _.forEach(all_managers, (manager) => {
      if (data.manager_id === manager.id) {
        data.location = manager.office_location;
        if (manager.office_location === "uk") {
          data.currency = "GBP";
        } else if (manager.office_location === "turkey") {
          data.currency = "LIRA";
        } else {
          data.currency = "USD";
        }
      }
    });
  }

  USER_API.patch(`/ClientRegistrations/${id}`, data)
    .then((res) => {
      dispatch(getUsers());
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};
