import { common } from "../../constants/actionTypes";
import { COUNTRIES_API_URL } from "../../constants/global-constants";
import axios from "axios";
import _ from "lodash";

export const loaderState = (state) => (dispatch) => {
  dispatch({ type: common.LOADER, payload: state });
};

export const selectedNav = (nav) => (dispatch) => {
  dispatch({ type: common.SELECTED_URL, payload: nav });
};

export const getCountriesData = () => (dispatch) => {
  axios
    .get(`${COUNTRIES_API_URL}/all`)
    .then((res) => {
      dispatch({ type: common.COUNTRIES_DATA, payload: res.data });
      // dispatch(loaderState(false));
    })
    .catch((err) => {
      alert(err.message);
      // dispatch(loaderState(false));
    });
};

export const sortData = (objArray, type, orderBy, order) => {
  let data = _.orderBy(objArray, [orderBy], [order]);
  return {
    type,
    payload: data,
  };
};
