import React from "react";
// import ReactPaginate from "react-paginate";
import Paginations from "react-js-pagination";

const Pagination = ({ activePage, perPage, totalPages, handlePageChange }) => {
  return (
    <Paginations
      prevPageText={<i className="fa fa-angle-left" />}
      nextPageText={<i className="fa fa-angle-right" />}
      firstPageText={<i className="fa fa-angle-double-left" />}
      lastPageText={<i className="fa fa-angle-double-right" />}
      activePage={activePage}
      itemsCountPerPage={perPage}
      totalItemsCount={totalPages}
      onChange={handlePageChange}
      onClick={() => console.log("")}
    />
    // <ReactPaginate
    //   previousLabel={<i class="fa fa-chevron-left" aria-hidden="true" />}
    //   nextLabel={<i class="fa fa-chevron-right" aria-hidden="true" />}
    //   breakLabel={"..."}
    //   breakClassName={"break-me"}
    //   pageCount={pageCount}
    //   initialPage={0}
    //   marginPagesDisplayed={1}
    //   pageRangeDisplayed={6}
    //   onPageChange={handlePageClick}
    //   containerClassName={"pagination"}
    //   subContainerClassName={"pages pagination"}
    //   activeClassName={"active"}
    // />
  );
};

export default Pagination;
