import React from "react";
import { Bar } from "react-chartjs-2";
import { getCurrencySymbol } from "../../helpers";

function _tick(value, index, values) {
  return value;
}

function _Xtick(value, index, values) {
  return value + " (" + getCurrencySymbol(value) + ")";
}

export default function Mangers({ data, height, width }) {
  let lineLegend = {
    display: false,
    position: "left",
    labels: { boxWidth: 50, fontSize: 16 },
  };
  let options = {
    maintainAspectRatio: false,
    segmentShowStroke: false,
    animateScale: true,
    tooltips: {
      enable: true,
      titleFontSize: 14,
      bodyFontSize: 14,
      yPadding: 8,
      titleSpacing: 3,
      footerFontSize: 14,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            callback: _Xtick,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: _tick,
          },
        },
      ],
    },
  };
  return (
    <Bar
      id="manager-chart"
      height={height}
      width={width}
      data={data ? data : []}
      options={options}
      legend={lineLegend}
    />
  );
}
