import React from "react";

const BankDetails = ({ bankDetails }) => {
  return (
    <div id="bankDetailsModal" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
            <h4 className="modal-title">Bank Details</h4>
          </div>
          <div className="modal-body">
            <div className="user-info">
              <div>
                <div className="user-label">Name</div>
                <div className="label-value">
                  {bankDetails ? bankDetails.first_name : "NA"}
                  {bankDetails ? " " + bankDetails.last_name : ""}
                </div>
              </div>
              <div>
                <div className="user-label">Country</div>
                <div className="label-value">
                  {bankDetails ? bankDetails.country : "NA"}
                </div>
              </div>
            </div>
            <hr />
            <div className="user-info">
              <div>
                <div className="user-label">Account Number</div>
                <div className="label-value">
                  {bankDetails ? bankDetails.bank_account_number : "NA"}
                </div>
              </div>
              <div>
                <div className="user-label">Sort Code</div>
                <div className="label-value">
                  {bankDetails ? bankDetails.sort_code : "NA"}
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="modal-footer">
            <button
              style={{ backgroundColor: "#13131a" }}
              type="button"
              className="btn"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
