import React from "react";
import moment from "moment";
import TablePagination from "../Common/Pagination";
import { isEmptyOrNull, addCommas, getCurrencySymbol } from "../../helpers";

const TransactionHistory = ({
  goto,
  transactions,
  id,
  handlePageChange,
  activePage,
  perPage,
  totalPages,
}) => {
  return (
    <>
      <div className="table-responsive">
        <div className="transaction-data">
          <table className="table" id={id ? `#${id}` : ""}>
            <thead>
              <tr>
                <td>ID</td>
                <td>DATE & TIME</td>
                {id ? null : <td>INVESTOR</td>}
                <td>AMOUNT</td>
                <td>INVESTOR BALANCE</td>
                <td>TRANSACTION TYPE</td>
              </tr>
            </thead>
            <tbody>
              {transactions
                ? transactions.map((transaction) => {
                    let type =
                      transaction && transaction.transaction_type
                        ? transaction.transaction_type.toLowerCase()
                        : "";
                    return (
                      <tr key={transaction.transaction_id}>
                        <td>{transaction.transaction_id}</td>
                        <td>
                          {!isEmptyOrNull(transaction.date_time)
                            ? moment(transaction.date_time).format("DD/MM/YYYY")
                            : ""}
                        </td>
                        {id ? null : (
                          <td>
                            {transaction.userData
                              ? transaction.userData.first_name +
                                " " +
                                transaction.userData.last_name
                              : ""}{" "}
                            (
                            {transaction.userData
                              ? transaction.userData.account_number
                              : ""}
                            )
                          </td>
                        )}
                        <td>
                          {!isEmptyOrNull(transaction.amount)
                            ? getCurrencySymbol(transaction.currency) +
                              addCommas(transaction.amount)
                            : getCurrencySymbol(transaction.currency) + "0"}
                        </td>
                        <td>
                          {transaction.userData
                            ? !isEmptyOrNull(transaction.new_balance)
                              ? getCurrencySymbol(transaction.currency) +
                                addCommas(transaction.new_balance)
                              : getCurrencySymbol(transaction.currency) + "0"
                            : getCurrencySymbol(transaction.currency) + "0"}
                        </td>
                        <td className="transaction-type">
                          <span
                            style={{
                              color:
                                type === "deposit"
                                  ? "#27AE60"
                                  : type === "withdrawal"
                                  ? "#C0392B"
                                  : "#1F618D",
                              backgroundColor:
                                type === "deposit"
                                  ? "rgb(34, 153, 84, 0.2)"
                                  : type === "withdrawal"
                                  ? "rgb(169, 50, 38, 0.2)"
                                  : "rgb(31, 97, 141, 0.2)",
                            }}
                          >
                            {!isEmptyOrNull(transaction.transaction_type)
                              ? transaction.transaction_type
                              : ""}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        {id ? null : (
          <div
            className="transaction-footer"
            onClick={() => goto("/transactions")}
          >
            VIEW ALL TRANSACTIONS
          </div>
        )}
      </div>
      {id && transactions && transactions.length > 0 ? (
        <div style={{ textAlign: "center" }}>
          <TablePagination
            activePage={activePage}
            perPage={perPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      ) : null}
      {transactions && transactions.length > 0 ? null : (
        <div className="no-data">
          <i>No data found...</i>
        </div>
      )}
    </>
  );
};

export default TransactionHistory;
