import { USER_API } from "../../api";
import { transactions } from "../../constants/actionTypes";
import swal from "sweetalert";
import { loaderState } from "../index";
import { getMonthYearString } from "../../helpers";
import moment from "moment";
import { getUsersBankDetails, updateUser } from "../users";
import _ from "lodash";

export const getTransactions = (page, limit, dashboard) => (
  dispatch,
  getState
) => {
  const { login_user_info } = getState().auth;
  let users_ids = [];
  _.forEach(login_user_info.investors, (investor) => {
    users_ids.push(investor.account_number);
  });
  let skip = (Number(page) - 1) * Number(limit);
  let filter = {
    where: {
      status: { neq: "Pending" },
    },
    order: "date_time DESC",
  };
  if (users_ids.length > 0) {
    filter.where.account_number = { inq: { ...users_ids } };
  }
  if (limit) {
    filter.limit = limit;
  }
  if (page) {
    filter.skip = skip;
  }

  let type = dashboard
    ? transactions.DASHBOARD_TRANSACTIONS
    : transactions.ALL_TRANSACTIONS;

  USER_API.get(`/TransactionHistroy?filter=${JSON.stringify(filter)}`)
    .then((res) => {
      dispatch({ type, payload: res.data });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const getTransactionsCount = () => (dispatch) => {
  USER_API.get(`/TransactionHistroy`)
    .then((res) => {
      dispatch({
        type: transactions.TRANSACTIONS_COUNT,
        payload: res.data.count,
      });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const getWithDrawals = () => (dispatch, getState) => {
  const { login_user_info } = getState().auth;
  let users_ids = [];
  _.forEach(login_user_info.investors, (investor) => {
    users_ids.push(investor.account_number);
  });

  let filter = {
    where: {
      transaction_type: "WithDrawal",
      account_number: { inq: { ...users_ids } },
    },
    order: "date_time DESC",
  };

  USER_API.get(`/TransactionHistroy?filter=${JSON.stringify(filter)}`)
    .then((res) => {
      let account_numbers = [];
      _.forEach(res.data, (d) => {
        account_numbers.push(d.account_number);
      });
      dispatch(
        getUsersBankDetails(account_numbers, res.data, transactions.WITHDRAWALS)
      );
      // dispatch({ type: transactions.WITHDRAWALS, payload: res.data });
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const getWithDrawalsCount = () => (dispatch, getState) => {
  const { login_user_info } = getState().auth;
  let users_ids = [];
  _.forEach(login_user_info.investors, (investor) => {
    users_ids.push(investor.account_number);
  });

  let where = {
    transaction_type: "WithDrawal",
    account_number: { inq: { ...users_ids } },
  };

  USER_API.get(`/TransactionHistroy/count?where=${JSON.stringify(where)}`)
    .then((res) => {
      dispatch({
        type: transactions.WITHDRAWALS_COUNT,
        payload: res.data.count,
      });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const updateWithdrawalStatus = (ids, status, drawal) => (
  dispatch,
  getState
) => {
  const { withDrawals } = getState().transactions;
  const { all_users } = getState().users;
  let investor = null;
  _.forEach(all_users, (user) => {
    if (user.account_number === drawal.account_number) {
      investor = user;
    }
  });
  let where = {
    transaction_id: { inq: { ...ids } },
  };
  let dataToUpload = {
    status: status,
  };
  if (drawal.status === "Pending" && status === "Completed") {
    dataToUpload.new_balance = Number(drawal.balance) - Number(drawal.amount);
  }
  USER_API.patch(
    `/TransactionHistroy?where=${JSON.stringify(where)}`,
    dataToUpload
  )
    .then((res) => {
      _.forEach(withDrawals, (withDrawal) => {
        if (ids.includes(withDrawal.transaction_id)) {
          withDrawal.status = status;
        }
      });
      if (drawal.status !== "Pending" && status === "Completed") {
        let newBalance = Number(drawal.balance) - Number(drawal.amount);

        dispatch(updateUser([investor.id], { balance: newBalance }));
      }
      // dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const selectedWithdrawal = (data) => {
  return {
    type: transactions.SELECTED_WITHDRAWAL,
    payload: data,
  };
};

export const addTransaction = (data, investor) => (dispatch, getState) => {
  let trans = getState().transactions.transactions;
  let month_year = getMonthYearString();

  USER_API.post(`/TransactionHistroy`, { ...data, month_year })
    .then((res) => {
      let newTransaction = { ...res.data, userData: investor };
      let payload = [...trans, newTransaction];
      payload = _.orderBy(payload, ["date_time"], ["desc"]);
      dispatch({
        type: transactions.ALL_TRANSACTIONS,
        payload,
      });
      //let newBalance = Number(data.amount) + Number(investor.balance);
      dispatch(updateUser([investor.id], { balance: data.new_balance }));
      swal("Transaction added successfully!", {
        icon: "success",
      });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const deteleTransaction = (id) => (dispatch, getState) => {
  let transactionss = getState().transactions.transactions;

  USER_API.delete(`/TransactionHistroy/${id}`)
    .then((res) => {
      let updatedTransactions = [];
      _.forEach(transactionss, (trans) => {
        if (trans.transaction_id !== id) {
          updatedTransactions.push(trans);
        }
      });
      dispatch({
        type: transactions.ALL_TRANSACTIONS,
        payload: updatedTransactions,
      });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const addWithDrawal = (data) => (dispatch) => {
  data.status = "Pending";
  data.transaction_type = "Withdrawal";
  if (!data.withdrawl_type) {
    data.withdrawl_type = "Free";
  }
  if (!data.currency) {
    data.currency = "GBP";
  }
  data.date_time = moment().format();
  data.amount = Number(data.amount);
  data.month_year = getMonthYearString();
  USER_API.post("/TransactionHistroy", data)
    .then((res) => {
      // let payload = [...withDrawals, res.data];
      // payload = _.orderBy(payload, ["date_time"], ["desc"]);
      // dispatch({
      //   type: transactions.WITHDRAWALS,
      //   payload,
      // });
      // dispatch(loaderState(false));
      dispatch(getWithDrawals());
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};
