import React from "react";
import { connect } from "react-redux";
import {
	getUsers,
	loaderState,
	selectedUser,
	getTransactions,
	getWithDrawals,
	// getManagers,
	sortData,
	updateUser,
	addInvestor,
	updateInvestor,
} from "../../actions";
import { users as userTypes } from "../../constants/actionTypes";
import { removeNullValues, isEmptyOrNull, filterData } from "../../helpers";
import _ from "lodash";
import swal from "sweetalert";
import $ from "jquery";

import UsersComp from "../../components/Users";
import moment from "moment";

class Users extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			edit: false,
			selected: [],
			perPage: 10,
			activePage: 1,
			wActivePage: 1,
			sortBy: "Newest",
		};
	}

	componentDidMount() {
		this.props.loaderState(true);
		this.props.getUsers();
		const { transactions, withDrawals, managers } = this.props;
		if (!transactions) {
			this.props.getTransactions();
		}
		if (!withDrawals) {
			this.props.getWithDrawals();
		}
		// if (!managers) {
		//   this.props.getManagers();
		// }
	}

	onClickEdit = (user) => {
		this.setState({ edit: true });
		this.props.selectedUser(user);
	};

	onClickAction = (user, e) => {
		let selected = this.state.selected;
		let ids = [];
		let status = "";
		let action = "";
		let update = false;
		if (user) {
			ids = [user.id];
			status = "Closed";
			update = true;
		}
		if (e) {
			if (selected.length > 0) {
				ids = [...selected];
				update = true;
				action = e.target.value;
				this.setState({ action });
				status = action === "Delete" ? "Closed" : action === "Activate" ? "Active" : "Paused";
			} else {
				update = false;
			}
		}
		if (update) {
			swal({
				title: "Are you sure?",
				text: "You want to update the status!",
				icon: "warning",
				buttons: ["Cancel", "Update"],
				dangerMode: true,
			}).then((willDelete) => {
				if (willDelete) {
					this.props.loaderState(true);
					this.props.updateUser(ids, { status });
					this.setState({ selected: [] });
					this.setState({ action: "Actions" });
				}
			});
		} else {
			this.setState({ action: "Actions" });
			swal("", "Please select records", "info");
		}
	};

	onSelectUser = (user) => {
		this.props.selectedUser(user);
	};

	resetState = () => {
		this.setState({
			first_name: null,
			last_name: null,
			province: null,
			city: null,
			email: null,
			mobile: null,
			password: null,
			confirmPassword: null,
			status: null,
			manager_id: null,
			account_number: null,
			special_investor: null,
			special_percentage: null,
		});
	};

	addOrUpdate = () => {
		let state = { ...this.state };

		let data = {
			first_name: state.first_name,
			last_name: state.last_name,
			province: state.province,
			city: state.city,
			email: state.email,
			mobile: state.mobile,
			password: state.password,
			confirmPassword: state.confirmPassword,
			status: state.status,
			manager_id: state.manager_id ? Number(state.manager_id) : null,
			account_number: state.account_number,
			special_investor: state.special_investor === "1" ? true : false,
			special_percentage: state.special_percentage,
			currency: state.currency,
		};

		data = removeNullValues(data);
		if (state.edit) {
			if (JSON.stringify(data) !== "{}") {
				if (data.special_investor === "1" && isEmptyOrNull(data.special_percentage)) {
					swal("", "Please enter percentage for special user", "info");
				} else {
					if (Number(data.special_percentage) > 100) {
						swal("", "Percentage should not be greater than 100!", "info");
					} else {
						this.resetState();
						data.special_percentage = Number(data.special_percentage ? data.special_percentage : 0);
						if (data.special_percentage > 0) {
							data.special_investor = true;
						}
						data.date_time = moment().format();
						const { selected_user } = this.props;
						delete data.account_number;
						this.props.loaderState(true);
						this.props.updateInvestor(selected_user.id, data);
						$(".close").click();
					}
				}
			} else {
				$(".close").click();
			}
		} else if (
			isEmptyOrNull(data.first_name) ||
			isEmptyOrNull(data.last_name) ||
			isEmptyOrNull(data.password) ||
			isEmptyOrNull(data.confirmPassword) ||
			isEmptyOrNull(data.mobile) ||
			isEmptyOrNull(data.email) ||
			isEmptyOrNull(data.currency)
		) {
			swal("", "First Name, Last Name, Email, Mobile, Password and Confirm Password are the required fields", "info");
		} else {
			if (data.password !== data.confirmPassword) {
				swal("", `Password and confirm password doesn't match`, "error");
			} else {
				delete data.confirmPassword;
				if (data.special_investor === "1" && isEmptyOrNull(data.special_percentage)) {
					swal("", "Please enter percentage for special user", "info");
				} else {
					if (Number(data.special_percentage) > 100) {
						swal("", "Percentage should not be greater than 100!", "info");
					} else {
						this.resetState();
						data.special_percentage = Number(data.special_percentage ? data.special_percentage : 0);
						if (data.special_percentage > 0) {
							data.special_investor = true;
						}
						this.props.loaderState(true);
						this.props.addInvestor(data);
						$(".close").click();
					}
				}
			}
		}
	};

	onChangePhoneNumber = (value, data, event) => {
		this.setState({
			mobile: value,
			account_number: data.countryCode.toUpperCase(),
		});
	};

	onChange = ({ target }) => {
		const { id, value } = target;
		if (id === "status") {
			if (value === "Delete") {
				this.setState({ [id]: "Closed" });
			} else {
				this.setState({ [id]: value });
			}
		} else {
			this.setState({ [id]: value });
		}
	};

	onClickAddUser = () => {
		this.setState({ edit: false });
		this.props.selectedUser(null);
	};

	setSelected = (selected) => {
		this.setState({ selected });
	};

	getUserTransactions = (transactions) => {
		const { selected_user } = this.props;
		let userTransactions = [];
		if (selected_user) {
			_.forEach(transactions, (transaction) => {
				if (transaction.account_number === selected_user.account_number) {
					userTransactions.push(transaction);
				}
			});
		}
		const { perPage, activePage } = this.state;
		let lastIndex = Number(perPage) * Number(activePage);
		let startIndex = lastIndex - Number(perPage);

		return {
			TransData: userTransactions.slice(startIndex, lastIndex),
			TransCount: userTransactions.length,
		};
	};

	getUserWithdrawals = (withdrawals) => {
		const { selected_user } = this.props;
		let userWithdrawals = [];
		if (selected_user) {
			_.forEach(withdrawals, (withdrawal) => {
				if (withdrawal.account_number === selected_user.account_number) {
					userWithdrawals.push(withdrawal);
				}
			});
		}
		const { perPage, wActivePage } = this.state;
		let lastIndex = Number(perPage) * Number(wActivePage);
		let startIndex = lastIndex - Number(perPage);

		return {
			withdrawalData: userWithdrawals.slice(startIndex, lastIndex),
			withdrawalCount: userWithdrawals.length,
		};
	};

	getManagerInfo = () => {
		const { selected_user, managers } = this.props;
		let manager = null;
		if (selected_user) {
			_.forEach(managers, (manag) => {
				if (selected_user.manager_id !== null && selected_user.manager_id === manag.id) {
					manager = manag;
				}
			});
		}
		return manager;
	};

	handlePageChange = (value, obj) => {
		if (obj === "transaction") {
			this.setState({ activePage: value });
		} else {
			this.setState({ wActivePage: value });
		}
	};

	sort = (order, sortBy) => {
		this.setState({ sortBy });
		const { users } = this.props;
		this.props.sortData(users, userTypes.ALL_USERS, "date_time", order);
	};

	filter = (array, text) => {
		let filteredData = filterData(array, text);
		return filteredData;
	};

	onFilterTextChange = ({ target }) => {
		this.setState({ filterText: target.value });
	};

	onClickClose = () => {
		this.resetState();
		$(".close").click();
	};

	render() {
		const { users, selected_user, withDrawals, transactions, managers } = this.props;
		const { edit, selected, perPage, activePage, wActivePage, sortBy, filterText, action } = this.state;
		let stateData = { ...this.state };

		let { TransData, TransCount } = transactions ? this.getUserTransactions(transactions) : [];
		let { withdrawalData, withdrawalCount } = withDrawals ? this.getUserWithdrawals(withDrawals) : [];
		let managerInfo = this.getManagerInfo();

		let filteredUsers = !isEmptyOrNull(filterText) ? this.filter(users, filterText) : users;

		return (
			<UsersComp
				onClickClose={() => this.onClickClose()}
				resetState={() => this.resetState()}
				users={filteredUsers}
				selectedUser={selected_user}
				heading="Investors"
				edit={edit}
				selected={selected}
				managerInfo={managerInfo}
				setSelected={(newSelected) => this.setSelected(newSelected)}
				onSelectUser={(user) => this.onSelectUser(user)}
				onClickEdit={(user) => this.onClickEdit(user)}
				addOrUpdate={() => this.addOrUpdate()}
				onChange={(e) => this.onChange(e)}
				onClickAddUser={() => this.onClickAddUser()}
				onClickAction={(user, e) => this.onClickAction(user, e)}
				stateData={stateData}
				investor={true}
				modalId="viewUserModal"
				userWithdrawals={withdrawalData}
				userTransactions={TransData}
				handlePageChange={(value, obj) => this.handlePageChange(value, obj)}
				activePage={activePage}
				perPage={perPage}
				totalPages={TransCount}
				wActivePage={wActivePage}
				wTotalPages={withdrawalCount}
				sort={(order, sortBy) => this.sort(order, sortBy)}
				sortBy={sortBy}
				managers={managers}
				onChangePhoneNumber={(value, data, event) => this.onChangePhoneNumber(value, data, event)}
				onFilterTextChange={(e) => this.onFilterTextChange(e)}
				action={action}
			/>
		);
	}
}

const mapStatePropsToProps = (state) => {
	return {
		users: state.users.all_users,
		selected_user: state.users.selected_user,
		transactions: state.transactions.transactions,
		withDrawals: state.transactions.withDrawals,
		managers: state.managers.all_managers,
	};
};

export default connect(mapStatePropsToProps, {
	getUsers,
	loaderState,
	selectedUser,
	getTransactions,
	getWithDrawals,
	// getManagers,
	sortData,
	updateUser,
	addInvestor,
	updateInvestor,
})(Users);
