import React, { Component } from "react";
import { resetPassword, loaderState } from "../../actions";
import { isEmptyOrNull } from "../../helpers";
import { connect } from "react-redux";
import swal from "sweetalert";
import $ from "jquery";

class ResetPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
    };
  }

  onClickResetPassword = () => {
    const { password, confirmPassword } = this.state;
    const { manager } = this.props;
    if (isEmptyOrNull(password) || isEmptyOrNull(confirmPassword)) {
      swal("", "Password and Confirm Password are required fields", "info");
    } else if (password !== confirmPassword) {
      swal("", "Password and Confirm password does not match", "info");
    } else {
      $(".close").click();
      this.props.loaderState(true);
      this.props.resetPassword({ id: manager.id, password });
    }
  };

  render() {
    return (
      <div id="resetPasswordModal" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">Reset Password</h4>
            </div>
            <div className="modal-body" style={{ margin: "20px" }}>
              <div className="form-group">
                <input
                  onChange={(e) => this.setState({ password: e.target.value })}
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="Enter new password"
                />
              </div>
              <div className="form-group">
                <input
                  onChange={(e) =>
                    this.setState({ confirmPassword: e.target.value })
                  }
                  type="password"
                  id="confirmPassword"
                  className="form-control"
                  placeholder="Re-enter new password"
                />
              </div>
            </div>
            <div className="modal-footer" style={{ textAlign: "right" }}>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => this.onClickResetPassword()}
                style={{ marginRight: "10px" }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    manager: state.auth.login_user_info,
  };
};

export default connect(mapStateToProps, { loaderState, resetPassword })(
  ResetPasswordModal
);
