import React from "react";
import { addCommas, isEmptyOrNull, getCurrencySymbol } from "../../helpers";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import HelpIcon from "@material-ui/icons/Help";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const WithDrawalRequestModal = ({
  onChange,
  onAddWithDrawal,
  stateData,
  investors,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className="modal fade" id="withDrawalRequestModal" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <Icon className="fa fa-times" />
            </button>
            <h4 className="modal-title">Request Withdrawal</h4>
          </div>
          <div className="modal-body">
            <div className="current-balance">
              <span>Investor Balance</span>
              <span>
                {stateData && stateData.selectedInvestor
                  ? getCurrencySymbol(stateData.selectedInvestor.currency) +
                    addCommas(stateData.selectedInvestor.balance)
                  : "0"}
              </span>
            </div>
            <div className="row" style={{ marginBottom: "20px" }}>
              <div className="col-xs-12">
                <select
                  className="form-control"
                  defaultValue="Select Investor"
                  onChange={(e) => onChange(e)}
                  id="account_number"
                >
                  <option disabled>Select Investor</option>
                  {investors
                    ? investors.map((investor) => (
                        <option
                          value={investor.account_number}
                          key={investor.account_number}
                        >
                          {investor.first_name} {investor.last_name} (
                          {investor.account_number})
                        </option>
                      ))
                    : null}
                </select>
              </div>{" "}
            </div>
            <div className="row">
              <div className="col-xs-12">
                <input
                  type="text"
                  placeholder="Enter Amount"
                  className="form-control"
                  id="amount"
                  onChange={(e) => onChange(e)}
                />
              </div>
              {/* <div className="col-xs-6">
                <select
                  className="form-control"
                  defaultValue="Select Currency"
                  onChange={(e) => onChange(e)}
                  id="currency"
                >
                  <option disabled>Select Currency</option>
                  <option value="GBP">GBP (United Kingdom)</option>
                  <option value="EUR"> EUR (European Union)</option>
                  <option value="USD">USD (United States)</option>
                  <option value="AED">AED (Dubai)</option>
                  <option value="RUB">RUB (Russia)</option>
                  <option value="LIRA">LIRA (Turkey)</option>
                  <option value="KZT">KZT (Kazakhstan)</option>
                </select>{" "}
              </div> */}
            </div>
            <div>
              <div className="request-radio-btns">
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="Free"
                    onChange={onChange}
                  >
                    <FormControlLabel
                      value="Free"
                      control={<Radio color="primary" />}
                      label="Free"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="Instant"
                      control={<Radio color="primary" />}
                      label="Instant"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
                <div className="request-popover">
                  <Typography
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    <HelpIcon />
                  </Typography>

                  <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                      paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography>The content of the Popover.</Typography>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              // data-dismiss="modal"
              disabled={
                stateData &&
                // isEmptyOrNull(stateData.currency) ||
                (isEmptyOrNull(stateData.amount) ||
                  isEmptyOrNull(stateData.account_number))
                  ? "disabled"
                  : ""
              }
              onClick={() => onAddWithDrawal()}
            >
              Submit Request
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithDrawalRequestModal;
