import React from "react";
import { connect } from "react-redux";
import {
  selectedNav,
  getTransactions,
  loaderState,
  getTotalInvestments,
  getUsers,
  getActiveUsers,
  getRounds,
} from "../../actions";
import {
  goto,
  investorsChartData,
  getDashboardCurrenciesData,
  managersProfitChartData,
  getUpcomingInvestment,
  createRoundsData,
} from "../../helpers";
import _ from "lodash";
import DashboardComp from "../../components/Dashboard";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.loaderState(true);
    this.props.getUsers();
    this.props.getTransactions();
    this.props.getActiveUsers();
    this.props.getTotalInvestments();
    this.props.getTransactions(1, 5, true);
    this.props.getRounds();
  }

  onGoto = (path) => {
    this.props.loaderState(true);
    this.props.selectedNav(path);
    setTimeout(() => {
      goto(path);
    }, 100);
  };

  getTopInvestors = (investors) => {
    let all_investors = _.orderBy(investors, ({ balance }) => balance || "", [
      "desc",
    ]);
    let top = [];
    let count = 0;
    _.forEach(all_investors, (TI) => {
      if (count < 5) {
        if (TI.balance) {
          top.push(TI);
          count++;
        }
      }
    });

    return top;
  };

  getInvestorDataSet = () => {
    const { activeUsers } = this.props;
    let investors = _.orderBy(activeUsers, ["balance"], ["desc"]);
    investors = investors.slice(0, 25);

    let datasets = investorsChartData(investors);

    return datasets;
  };

  getStartedRound = (rounds) => {
    let startedRounds = 0;
    // const { manager_details } = this.props;
    _.forEach(rounds, (round) => {
      if (round.status === "Started") {
        startedRounds += 1;
        // _.forEach(manager_details.investors, (investor) => {
        //   if (investor.account_number == round.account_number) {
        //     startedRounds += 1;
        //   }
        // });
      }
    });

    return startedRounds;
  };

  getInvestments(rounds) {
    let investments = [];

    _.forEach(rounds, (round) => {
      if (round.start_date && round.status === "Started") {
        investments.push(round);
        // let currentDate = moment(new Date(), "DD.MM.YYYY");
        // let startDate = moment(moment(round.start_date), "DD.MM.YYYY");
        // let diff = currentDate.diff(startDate, "days");
        // if (diff >= 27) {
        //   upcomingRounds.push(round);
        // }
      }
    });

    return investments;
  }

  render() {
    const {
      allInvestors,
      transactions,
      activeUsers,
      totalInvestment,
      totalInvestors,
      rounds,
      allTransacions,
      manager_details,
    } = this.props;

    const {
      graphLabels,
      GBPData,
      LIRAData,
      USDData,
      KZTData,
      AEDData,
      RUBData,
      EURData,
    } = allTransacions
      ? getDashboardCurrenciesData(
          _.orderBy(allTransacions, ["date_time"], "asc")
        )
      : {};

    const balance = {
      labels: graphLabels ? graphLabels : [],
      datasets: [
        {
          fill: false,
          label: "(£) Currency GBP",
          backgroundColor: "rgba(0,0,255,0.4)",
          borderColor: "rgba(0,0,255,1)",
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(0,0,255,1)",
          pointHoverBackgroundColor: "rgba(0,0,255,1)",
          pointHoverBorderColor: "rgba(0,0,255,1)",
          pointRadius: 3,
          data: GBPData ? GBPData : [],
        },
        {
          fill: false,
          label: "(₺) Currency LIRA",
          backgroundColor: "rgba(255,0,0,0.4)",
          borderColor: "rgba(255,0,0,1)",
          pointHoverBackgroundColor: "rgba(255,0,0,1)",
          pointHoverBorderColor: "rgba(255,0,0,1)",
          pointHoverBackgroundColor: "rgba(255,0,0,1)",
          pointHoverBorderColor: "rgba(255,0,0,1)",
          pointRadius: 3,
          data: LIRAData ? LIRAData : [],
          type: "line",
        },
        {
          fill: false,
          label: "($) Currency USD",
          backgroundColor: "rgba(255,255,0,0.4)",
          borderColor: "rgba(255,255,0,1)",
          pointHoverBackgroundColor: "rgba(255,255,0,1)",
          pointHoverBorderColor: "rgba(255,255,0,1)",
          pointHoverBackgroundColor: "rgba(255,255,0,1)",
          pointHoverBorderColor: "rgba(255,255,0,1)",
          pointRadius: 3,
          data: USDData ? USDData : [],
          type: "line",
        },
        {
          fill: false,
          label: "(₸) Currency KZT",
          backgroundColor: "rgba(255,0,255,0.4)",
          borderColor: "rgba(255,0,255,1)",
          pointHoverBackgroundColor: "rgba(255,0, 255,1)",
          pointHoverBorderColor: "rgba(255,0,255,1)",
          pointHoverBackgroundColor: "rgba(255,0,255,1)",
          pointHoverBorderColor: "rgba(255,0,255,1)",
          pointRadius: 3,
          data: KZTData ? KZTData : [],
          type: "line",
        },
        {
          fill: false,
          label: "(د.إ)" + " Currency AED",
          backgroundColor: "rgba(0,255,255,0.4)",
          borderColor: "rgba(0,255,255,1)",
          pointHoverBackgroundColor: "rgba(0,255, 255,1)",
          pointHoverBorderColor: "rgba(0,255,255,1)",
          pointHoverBackgroundColor: "rgba(0,255,255,1)",
          pointHoverBorderColor: "rgba(0,255,255,1)",
          pointRadius: 3,
          data: AEDData ? AEDData : [],
          type: "line",
        },
        {
          fill: false,
          label: "(₽) Currency RUB",
          backgroundColor: "rgba(155,255,255,0.4)",
          borderColor: "rgba(155,255,255,1)",
          pointHoverBackgroundColor: "rgba(155,255, 255,1)",
          pointHoverBorderColor: "rgba(155,255,255,1)",
          pointHoverBackgroundColor: "rgba(155,255,255,1)",
          pointHoverBorderColor: "rgba(155,255,255,1)",
          pointRadius: 3,
          data: RUBData ? RUBData : [],
          type: "line",
        },
        {
          fill: false,
          label: "(€) Currency EUR",
          backgroundColor: "rgba(155,0,255,0.4)",
          borderColor: "rgba(155,0,255,1)",
          pointHoverBackgroundColor: "rgba(155,0, 255,1)",
          pointHoverBorderColor: "rgba(155,0,255,1)",
          pointHoverBackgroundColor: "rgba(155,0,255,1)",
          pointHoverBorderColor: "rgba(155,0,255,1)",
          pointRadius: 3,
          data: EURData ? EURData : [],
          type: "line",
        },
      ],
    };

    const managerProfit = manager_details
      ? managersProfitChartData(manager_details)
      : {};

    let investorsDataset = activeUsers ? this.getInvestorDataSet() : null;

    let topInvestors = allInvestors ? this.getTopInvestors(allInvestors) : null;

    let startedRounds = rounds ? this.getStartedRound(rounds) : null;

    let roundsData = rounds ? createRoundsData(rounds) : [];
    let startedInvestments = this.getInvestments(roundsData);
    startedInvestments = _.orderBy(
      startedInvestments,
      ["remainingDays"],
      ["asc"]
    );
    // let upcomingRounds = getUpcomingInvestment(roundsData);
    // upcomingRounds = _.orderBy(upcomingRounds, ["remainingDays"], ["asc"]);

    return (
      <DashboardComp
        startedRounds={startedRounds}
        goto={(path) => this.onGoto(path)}
        balance={balance}
        investors={investorsDataset}
        topInvestors={topInvestors}
        totalInvestment={Number(totalInvestment).toFixed(2)}
        totalInvestors={totalInvestors}
        activeUsers={activeUsers}
        transactions={transactions}
        manager_details={manager_details}
        managerProfit={managerProfit}
        startedInvestments={startedInvestments}
        // upcomingRounds={upcomingRounds}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allInvestors: state.users.all_users,
    totalInvestment: state.users.total_investment,
    totalInvestors: state.users.total_investors,
    activeUsers: state.users.active_users,
    transactions: state.transactions.dashboard_transactions,
    rounds: state.rounds.all_rounds,
    allTransacions: state.transactions.transactions,
    manager_details: state.auth.login_user_info,
  };
};

export default connect(mapStateToProps, {
  selectedNav,
  loaderState,
  getTransactions,
  getTotalInvestments,
  getUsers,
  getActiveUsers,
  getRounds,
})(Dashboard);
