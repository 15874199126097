import React from "react";
import { addCommas } from "../../helpers";
import Card from "../Common/Card";
import Balance from "./Balance";
import Investors from "./Investors";
import TransactionHistory from "./TransactionHistory";
import TopInvestors from "./TopInvestors";
import Tabs from "../Common/tabs";
import ManagerChart from "./Manger";
import UpcomingInvestments from "./UpcomingInvestments";

export default function Dashboard({
  balance,
  investors,
  goto,
  topInvestors,
  managerProfit,
  activeUsers,
  transactions,
  startedRounds,
  manager_details,
  // upcomingRounds,
  startedInvestments,
}) {
  let GBP = balance.datasets[0].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  GBP = Number(GBP).toFixed(2);

  let LIRA = balance.datasets[1].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  LIRA = Number(LIRA).toFixed(2);

  let KZT = balance.datasets[3].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  KZT = Number(KZT).toFixed(2);

  let AED = balance.datasets[4].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  AED = Number(AED).toFixed(2);

  let RUB = balance.datasets[5].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  RUB = Number(RUB).toFixed(2);

  let EUR = balance.datasets[6].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  EUR = Number(EUR).toFixed(2);

  let USD = balance.datasets[2].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  USD = Number(USD).toFixed(2);

  return (
    <>
      <div className="comp-header">Dashboard</div>
      <div className="row">
        <div className="col-lg-2 col-sm-4">
          <Card>
            <div className="dashboard-card-title">GBP</div>
            <h3>£ {addCommas(GBP)}</h3>
          </Card>
        </div>
        <div className="col-lg-2  col-sm-4">
          <Card>
            <div className="dashboard-card-title">LIRA</div>
            <h3>₺ {addCommas(LIRA)}</h3>
          </Card>
        </div>
        <div className="col-lg-2 col-sm-4">
          <Card>
            <div className="dashboard-card-title">KZT</div>
            <h3>₸ {addCommas(KZT)}</h3>
          </Card>
        </div>
        <div className="col-lg-2 col-sm-4">
          <Card>
            <div className="dashboard-card-title">AED</div>
            <h3>د.إ {addCommas(AED)}</h3>
          </Card>
        </div>
        <div className="col-lg-2 col-sm-4">
          <Card>
            <div className="dashboard-card-title">RUB</div>
            <h3>₽ {addCommas(RUB)}</h3>
          </Card>
        </div>
        <div className="col-lg-2 col-sm-4">
          <Card>
            <div className="dashboard-card-title">USD</div>
            <h3>$ {addCommas(USD)}</h3>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <Card>
            <div className="dashboard-card-title">EUR</div>
            <h3>€ {addCommas(EUR)}</h3>
          </Card>
        </div>
        {/* <div className="col-lg-3">
          <Card>
            <div className="dashboard-card-title">Total Investments</div>
            <h3>£{totalInvestment ? addCommas(totalInvestment) : 0}</h3>
          </Card>
        </div> */}
        {/* <div className="col-lg-3">
          <Card>
            <div className="dashboard-card-title">Manager Profit</div>
            <h3>
              £{" "}
              {manager_details && manager_details.profit
                ? addCommas(Number(manager_details.profit).toFixed(2))
                : "0.00"}
            </h3>
          </Card>
        </div> */}
        <div className="col-sm-4">
          <Card>
            <div className="dashboard-card-title">Live Investment Rounds</div>
            <h3>{startedRounds}</h3>
          </Card>
        </div>
        <div className="col-sm-4">
          <Card>
            <div className="dashboard-card-title">Current Investor Number</div>
            <h3>
              {activeUsers ? addCommas(activeUsers.length).split(".")[0] : 0}
            </h3>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card>
            <Tabs
              tabs={["Balance Per Currency", "Investors", "Manager Profit"]}
              className="tabs"
            />
            <div className="tab-content">
              <div id="BalancePerCurrency" className="tab-pane active">
                <div style={{ maxHeight: "500px" }}>
                  <Balance data={balance} />
                </div>
              </div>
              <div id="Investors" className="tab-pane fade">
                <div style={{ maxHeight: "400px" }}>
                  <Investors data={investors} />
                </div>
              </div>
              <div id="ManagerProfit" className="tab-pane fade">
                <div style={{ maxHeight: "400px" }}>
                  <ManagerChart data={managerProfit} />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <Card>
            <div className="dashboard-card-title">Top Investors</div>
            <TopInvestors
              topInvestors={topInvestors}
              goto={(path) => goto(path)}
            />
          </Card>
        </div>
        <div className="col-lg-6">
          <Card>
            <div className="dashboard-card-title">Investments Rounds</div>
            <UpcomingInvestments
              startedInvestments={startedInvestments}
              // upcomingRounds={upcomingRounds}
              goto={(path) => goto(path)}
            />
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card>
            <div className="dashboard-card-title">Transaction History</div>
            <TransactionHistory
              goto={(path) => goto(path)}
              transactions={transactions}
            />
          </Card>
        </div>
      </div>
    </>
  );
}
