import React from "react";
import Icon from "@material-ui/core/Icon";
import userIcon from "../../assets/icons/avatar.png";
import Tabs from "../Common/tabs";
import Transactions from "../Dashboard/TransactionHistory";
import UserWithdrawals from "./UserWithdrawals";

const ViewUserModal = ({
  selectedUser,
  onClickDelete,
  onClickEdit,
  userTransactions,
  handlePageChange,
  activePage,
  perPage,
  totalPages,
  userWithdrawals,
  wActivePage,
  wTotalPages,
}) => {
  return (
    <div className="modal fade" id="viewUserModal" role="dialog">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <Icon className="fa fa-times" />
            </button>
            <div className="modal-title">
              <div className="media">
                <div className="media-left">
                  <img
                    src={
                      selectedUser && selectedUser.image
                        ? selectedUser.image
                        : userIcon
                    }
                    width="50"
                    height="50"
                  />
                </div>
                <div className="media-body">
                  <h5 className="media-heading" style={{ marginTop: "5px" }}>
                    {selectedUser ? selectedUser.first_name : ""}{" "}
                    {selectedUser ? selectedUser.last_name : ""}
                  </h5>
                  <p style={{ color: "gray" }}>
                    Investor ID: #
                    {selectedUser ? selectedUser.account_number : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-body" style={{ margin: "0 15px " }}>
            <Tabs
              tabs={["Details", "Transactions", "Withdrawals"]}
              className="tabs"
            />
            <div className="tab-content">
              <div id="Details" className="tab-pane active">
                <div className="user-info">
                  <div>
                    <div className="user-label">Email</div>
                    <div className="label-value">
                      {selectedUser ? selectedUser.email : "NA"}
                    </div>
                  </div>
                  <div>
                    <div className="user-label">Phone</div>
                    <div className="label-value">
                      {selectedUser ? "+" + selectedUser.mobile : "NA"}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="user-info">
                  <div>
                    <div className="user-label">City</div>
                    <div className="label-value">
                      {selectedUser && selectedUser.addressDetails
                        ? selectedUser.addressDetails.city
                        : "NA"}
                    </div>
                  </div>
                  <div>
                    <div className="user-label">Province</div>
                    <div className="label-value">
                      {selectedUser && selectedUser.addressDetails
                        ? selectedUser.addressDetails.province
                        : "NA"}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="user-info">
                  <div>
                    <div className="user-label">Postal Code</div>
                    <div className="label-value">
                      {selectedUser && selectedUser.addressDetails
                        ? selectedUser.addressDetails.postal_code
                        : "NA"}
                    </div>
                  </div>
                  <div>
                    <div className="user-label"> Investor Type</div>
                    <div className="label-value">
                      {selectedUser && selectedUser.special_investor === true
                        ? "Special"
                        : "Standard"}
                    </div>
                  </div>
                </div>
              </div>
              <div id="Transactions" className="tab-pane fade">
                <Transactions
                  handlePageChange={(value) =>
                    handlePageChange(value, "transaction")
                  }
                  activePage={activePage}
                  perPage={perPage}
                  totalPages={totalPages}
                  transactions={userTransactions}
                  id="investor-transactions"
                />
              </div>
              <div id="Withdrawals" className="tab-pane fade">
                <UserWithdrawals
                  withdrawals={userWithdrawals}
                  handlePageChange={(value) =>
                    handlePageChange(value, "withdrawal")
                  }
                  activePage={wActivePage}
                  perPage={perPage}
                  totalPages={wTotalPages}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              style={{ backgroundColor: "#13131a", marginLeft: "5px" }}
              type="button"
              className="btn"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              onClick={() => onClickDelete(selectedUser)}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#addUserModal"
              onClick={() => onClickEdit(selectedUser)}
            >
              Edit Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUserModal;
