import { loggedUser, companyInfo } from "../../constants/actionTypes";

const initialState = {
  login_user_info: null,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case loggedUser.UERS_INFO:
      return { ...state, login_user_info: action.payload };
    default:
      return state;
  }
}
export default authReducer;
