import React from "react";
import UserTable from "./EnhancedUserTable";
import AddUserModal from "./AddUserModal";
import ViewUserModal from "./ViewUserModal";

const Users = ({
  users,
  heading,
  onSelectUser,
  selectedUser,
  onClickAction,
  onClickEdit,
  edit,
  onChange,
  addOrUpdate,
  onClickAddUser,
  stateData,
  selected,
  setSelected,
  investor,
  modalId,
  userTransactions,
  userWithdrawals,
  handlePageChange,
  activePage,
  perPage,
  totalPages,
  wActivePage,
  wTotalPages,
  managerInfo,
  sort,
  sortBy,
  onChangePhoneNumber,
  onFilterTextChange,
  action,
  onClickClose,
  resetState,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-xs-4">
          <div className="comp-header">{heading}</div>
        </div>
        <div className="col-xs-8" style={{ textAlign: "right" }}>
          <button
            id="add-user-btn"
            data-toggle="modal"
            data-target="#addUserModal"
            className="btn btn-primary"
            onClick={() => onClickAddUser()}
            style={{ marginRight: "5px" }}
          >
            <i className="fa fa-user-plus" style={{ marginRight: "5px" }} />
            {heading === "Investors" ? "Add Investor" : "Add Manager"}
          </button>
          <div className="btn-group" id="comp-heading-grp-btns">
            <button type="button" className="btn" id="comp-heading-btn-text">
              <span style={{ color: "gray" }}>Sort by:</span> {sortBy}
            </button>
            <button
              id="comp-heading-dropdown-btn"
              type="button"
              className="btn dropdown-toggle"
              data-toggle="dropdown"
            >
              <span className="caret"></span>
            </button>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li onClick={() => sort("desc", "Newest")}>
                <a href="#newest">Newest</a>
              </li>
              <li onClick={() => sort("asc", "Oldest")}>
                <a href="#oldest">Oldest</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="usersTables">
        <UserTable
          rows={users}
          onSelectUser={(user) => onSelectUser(user)}
          selected={selected}
          setSelected={(newSelected) => setSelected(newSelected)}
          investor={investor}
          modalId={modalId}
          onClickAction={(e) => onClickAction(false, e)}
          onFilterTextChange={(e) => onFilterTextChange(e)}
          action={action}
        />
      </div>
      <AddUserModal
        onChangePhoneNumber={(value, data, event, state) =>
          onChangePhoneNumber(value, data, event, state)
        }
        onClickClose={() => onClickClose()}
        resetState={() => resetState()}
        selectedUser={selectedUser}
        stateData={stateData}
        addOrUpdate={() => addOrUpdate()}
        onChange={(e) => onChange(e)}
        edit={edit}
        addBtnName={edit ? "Update Investor" : "Add Investor"}
        heading={edit ? "Update Investor" : "Add New Investor"}
      />
      <ViewUserModal
        selectedUser={selectedUser}
        onClickEdit={(user) => onClickEdit(user)}
        onClickDelete={(user) => onClickAction(user)}
        userTransactions={userTransactions}
        handlePageChange={(value, obj) => handlePageChange(value, obj)}
        activePage={activePage}
        perPage={perPage}
        totalPages={totalPages}
        userWithdrawals={userWithdrawals}
        wActivePage={wActivePage}
        wTotalPages={wTotalPages}
        managerInfo={managerInfo}
      />
    </>
  );
};

export default Users;
