import React from "react";
import Icon from "@material-ui/core/Icon";
import PhoneInput from "react-phone-input-2";
import { isNullOrUndefined } from "../../helpers";

import "react-phone-input-2/lib/style.css";

const AddUserModal = ({
	selectedUser,
	addBtnName,
	heading,
	stateData,
	edit,
	onChange,
	addOrUpdate,
	managers,
	onChangePhoneNumber,
	onClickClose,
	resetState,
}) => {
	let specialInvestor =
		stateData && isNullOrUndefined(stateData.special_investor)
			? stateData.special_investor
			: selectedUser && selectedUser.special_investor
			? selectedUser.special_investor
			: "";

	return (
		<div className="modal fade" id="addUserModal" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal" onClick={() => resetState()}>
							<Icon className="fa fa-times" />
						</button>
						<h4 className="modal-title">{heading}</h4>
					</div>
					<div className="modal-body">
						<div className="media" style={{ marginBottom: "10px" }}>
							<div className="media-left">
								<div className="media-image">
									<i className="fa fa-camera" />
								</div>
							</div>
							<div className="media-body">
								<h5 className="media-heading" style={{ marginTop: "5px" }}>
									Upload Image
								</h5>
								<p style={{ color: "gray" }}>Please upload your image by clicking on Camera button</p>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="first_name">First Name</label>
									<input
										autoComplete="off"
										onChange={(e) => onChange(e)}
										type="text"
										placeholder="Enter First Name"
										className="form-control"
										id="first_name"
										value={
											stateData && isNullOrUndefined(stateData.first_name)
												? stateData.first_name
												: selectedUser && selectedUser.first_name
												? selectedUser.first_name
												: ""
										}
									/>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="last_name">Last Name</label>
									<input
										autoComplete="off"
										onChange={(e) => onChange(e)}
										type="text"
										placeholder="Enter Last Name"
										className="form-control"
										id="last_name"
										value={
											stateData && isNullOrUndefined(stateData.last_name)
												? stateData.last_name
												: selectedUser && selectedUser.last_name
												? selectedUser.last_name
												: ""
										}
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="status">Status</label>
									<select
										onChange={(e) => onChange(e)}
										className="form-control"
										id="status"
										value={
											stateData && isNullOrUndefined(stateData.status)
												? stateData.status
												: selectedUser && selectedUser.status
												? selectedUser.status
												: ""
										}
									>
										<option value="">Select Status</option>
										<option value="Active">Active</option>
										{edit ? <option value="Closed">Delete</option> : null}
										{edit ? <option value="Paused">Pause</option> : null}
									</select>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="mobile">Mobile Number</label>
									<PhoneInput
										id="mobile"
										country={"gb"}
										// onlyCountries={["gb", "us", "ca"]}
										value={
											stateData && isNullOrUndefined(stateData.mobile)
												? stateData.mobile
												: selectedUser && selectedUser.mobile
												? selectedUser.mobile
												: ""
										}
										onChange={(value, data, event) => onChangePhoneNumber(value, data, event, "mobile")}
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="email">Email</label>
									<input
										autoComplete="off"
										onChange={(e) => onChange(e)}
										type="text"
										placeholder="Enter Email"
										className="form-control"
										id="email"
										value={
											stateData && isNullOrUndefined(stateData.email)
												? stateData.email
												: selectedUser && selectedUser.email
												? selectedUser.email
												: ""
										}
									/>
								</div>
							</div>

							{edit ? null : (
								<>
									<div className="col-sm-6">
										<div className="form-group">
											<label htmlFor="password">Password</label>
											<input
												autoComplete="off"
												onChange={(e) => onChange(e)}
												type="password"
												placeholder="Enter Password"
												className="form-control"
												id="password"
												value={stateData && stateData.password !== null ? stateData.password : ""}
											/>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label htmlFor="confirmPassword">Confirm Password</label>
											<input
												autoComplete="off"
												onChange={(e) => onChange(e)}
												type="password"
												placeholder="Retype Password"
												className="form-control"
												id="confirmPassword"
												value={stateData && stateData.confirmPassword !== null ? stateData.confirmPassword : ""}
											/>
										</div>
									</div>
								</>
							)}

							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="special_investor">Special Investor</label>
									<select
										onChange={(e) => onChange(e)}
										className="form-control"
										id="special_investor"
										value={specialInvestor === true ? "1" : "0"}
									>
										<option value="">Select</option>
										<option value="1">Special</option>
										<option value="0">Standard</option>
									</select>
								</div>
							</div>
							{specialInvestor === true || specialInvestor === "1" ? (
								<div className="col-sm-6">
									<div className="form-group">
										<label htmlFor="special_percentage">Percentage</label>
										<input
											autoComplete="off"
											onChange={(e) => onChange(e)}
											type="number"
											placeholder="Percentage"
											className="form-control"
											id="special_percentage"
											value={
												stateData && isNullOrUndefined(stateData.special_percentage)
													? stateData.special_percentage
													: selectedUser && selectedUser.special_percentage
													? selectedUser.special_percentage
													: ""
											}
										/>
									</div>
								</div>
							) : null}
							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="currency">Currency</label>
									<select
										onChange={(e) => onChange(e)}
										className="form-control"
										id="currency"
										value={
											stateData && stateData.currency && isNullOrUndefined(stateData.currency)
												? stateData.currency
												: selectedUser && selectedUser.currency
												? selectedUser.currency
												: "default"
										}
									>
										<option disabled value="default">
											Select Currency
										</option>
										<option value="GBP">GBP (United Kingdom)</option>
										<option value="EUR"> EUR (European Union)</option>
										<option value="USD">USD (United States)</option>
										<option value="AED">AED (Dubai)</option>
										<option value="RUB">RUB (Russia)</option>
										<option value="LIRA">LIRA (Turkey)</option>
										<option value="KZT">KZT (Kazakhstan)</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button
							style={{ backgroundColor: "#13131a", marginLeft: "10px" }}
							type="button"
							className="btn"
							onClick={() => onClickClose()}
						>
							Close
						</button>
						<button type="button" className="btn btn-primary" onClick={() => addOrUpdate()}>
							<i className="fa fa-plus" /> {addBtnName}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddUserModal;
