import React from "react";
import WithDrawalTable from "./WithDrawalTable";
import BankDetails from "./BankDetails";
import WithDrawalRequestModal from "./WithDrawalRequestModal";

const WithDrawal = ({
  WithDrawals,
  setSelected,
  updateStatus,
  sort,
  onSelectWithdrawal,
  selectedWithdrawal,
  onClickActions,
  onFilterTextChange,
  onChange,
  stateData,
  investors,
  onAddWithDrawal,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-xs-6">
          <div className="comp-header">Withdrawal</div>
        </div>
        <div className="col-xs-6" style={{ textAlign: "right" }}>
          <button
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#withDrawalRequestModal"
            style={{ marginRight: "10px" }}
          >
            Add WithDrawal
          </button>
          <div className="btn-group" id="comp-heading-grp-btns">
            <button type="button" className="btn" id="comp-heading-btn-text">
              <span style={{ color: "gray" }}>Sort by:</span> {stateData.sortBy}
            </button>
            <button
              id="comp-heading-dropdown-btn"
              type="button"
              className="btn dropdown-toggle"
              data-toggle="dropdown"
            >
              <span className="caret"></span>
            </button>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li onClick={() => sort("desc", "Newest")}>
                <a href="#newest">Newest</a>
              </li>
              <li onClick={() => sort("asc", "Oldest")}>
                <a href="#oldest">Oldest</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <WithDrawalTable
        rows={WithDrawals}
        selected={stateData ? stateData.selected : 0}
        setSelected={(newSelected) => setSelected(newSelected)}
        updateStatus={(id, status) => updateStatus(id, status)}
        onSelectWithdrawal={(withdrawal) => onSelectWithdrawal(withdrawal)}
        onClickActions={(action) => onClickActions(action)}
        onFilterTextChange={(e) => onFilterTextChange(e)}
        action={stateData.action}
      />
      <BankDetails
        bankDetails={
          selectedWithdrawal && selectedWithdrawal.bankDetails
            ? selectedWithdrawal.bankDetails
            : null
        }
      />
      <WithDrawalRequestModal
        onChange={(e) => onChange(e)}
        stateData={stateData}
        investors={investors}
        onAddWithDrawal={() => onAddWithDrawal()}
      />
    </>
  );
};

export default WithDrawal;
