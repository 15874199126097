import React from "react";
// import Pagination from "../Common/Pagination";
import TransactionTable from "./TransactionTable";
import AddTransactionModal from "./AddTransactionModal";

const Transactions = ({
  transactions,
  setSelected,
  sort,
  onFilterTextChange,
  onChange,
  investors,
  onClickSave,
  onDelete,
  stateData,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-xs-6">
          <div className="comp-header">Transactions</div>
        </div>
        <div className="col-xs-6" style={{ textAlign: "right" }}>
          <button
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#add-transaction-modal"
          >
            Add Transaction
          </button>
          <div className="btn-group" id="withdrawal-btns">
            <button type="button" className="btn" id="withdrawal-text-btn">
              <span style={{ color: "gray" }}>Sort by:</span> {stateData.sortBy}
            </button>
            <button
              id="withdrawal-dropdown-btn"
              type="button"
              className="btn dropdown-toggle"
              data-toggle="dropdown"
            >
              <span className="caret"></span>
            </button>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li onClick={() => sort("desc", "Newest")}>
                <a href="#newest">Newest</a>
              </li>
              <li onClick={() => sort("asc", "Oldest")}>
                <a href="#oldest">Oldest</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <TransactionTable
        rows={transactions}
        selected={stateData.selected}
        setSelected={(newSelected) => setSelected(newSelected)}
        onFilterTextChange={(e) => onFilterTextChange(e)}
        onDelete={(e) => onDelete(e)}
        action={stateData.action}
      />
      <AddTransactionModal
        onChange={(e) => onChange(e)}
        investors={investors}
        onClickSave={() => onClickSave()}
        stateData={stateData}
      />
    </>
  );
};

export default Transactions;
