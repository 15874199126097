import React from "react";
import { Pie } from "react-chartjs-2";

export default function Investors({ data, height, width }) {
  let lineLegend = {
    display: false,
    position: "left",
    labels: { boxWidth: 50, fontSize: 16 },
  };
  let options = {
    maintainAspectRatio: false,
    segmentShowStroke: false,
    animateScale: true,
    tooltips: {
      titleFontSize: 14,
      bodyFontSize: 14,
      yPadding: 8,
      titleSpacing: 3,
      footerFontSize: 14,
    },
  };
  return (
    <Pie
      id="investor-chart"
      height={height}
      width={width}
      data={data ? data : []}
      options={options}
      legend={lineLegend}
    />
  );
}
