import React, { Component } from "react";
import { connect } from "react-redux";
import { rounds as ronds } from "../../constants/actionTypes";
import { loaderState, getRounds, sortData } from "../../actions";
import {
  createRoundsData,
  filterData,
  isEmptyOrNull,
  getUpcomingInvestment,
} from "../../helpers";
import _ from "lodash";
import moment from "moment";

import Rounds from "../../components/InvestmentRounds";

class UpcomingRounds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: "Newest",
    };
  }

  componentDidMount() {
    this.props.loaderState(true);
    this.props.getRounds();
  }

  filter = (array, text) => {
    let filteredData = filterData(array, text);
    return filteredData;
  };

  onFilterTextChange = ({ target }) => {
    this.setState({ filterText: target.value });
  };

  sort = (order, sortBy) => {
    this.setState({ sortBy });
    const { rounds } = this.props;
    this.props.sortData(rounds, ronds.ROUNDS, "start_date", order);
  };

  render() {
    const { rounds } = this.props;
    const { filterText, sortBy } = this.state;
    let data = rounds ? createRoundsData(rounds) : [];
    let upcomingRounds = getUpcomingInvestment(data);

    let filteredRounds = !isEmptyOrNull(filterText)
      ? this.filter(upcomingRounds, filterText)
      : upcomingRounds;

    filteredRounds = _.orderBy(filteredRounds, ["remainingDays"], ["asc"]);

    return (
      <Rounds
        title="Upcoming Investments"
        data={filteredRounds}
        onFilterTextChange={(e) => this.onFilterTextChange(e)}
        sortBy={sortBy}
        sort={(order, sortBy) => this.sort(order, sortBy)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rounds: state.rounds.all_rounds,
  };
};

export default connect(mapStateToProps, { loaderState, getRounds, sortData })(
  UpcomingRounds
);
