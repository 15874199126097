import React from "react";
import InvestmentTable from "./InvestmentTable";

export default function InvestmentRound({
  data,
  onChangeStatus,
  selectedRound,
  onFilterTextChange,
  sort,
  sortBy,
  onSelectRound,
  onClickStart,
  onClickDelete,
  onChangeUpcoming,
  title,
}) {
  return (
    <>
      <div className="row">
        <div className="col-xs-6">
          <div className="comp-header">
            {" "}
            {title ? title : "Investment Rounds"}
          </div>
        </div>
        {/* <div className="col-xs-3"></div>
        {title ? (
          <div className="col-xs-3" style={{ textAlign: "right" }}>
            <select className="form-control">
              <option disabled selected>
                select days
              </option>
              <option>3</option>
              <option>4</option>
              <option>7</option>
              <option>10</option>
            </select>
           
          </div>
        ) : null} */}
        {/* <div className="btn-group" id="comp-heading-grp-btns">
              <button type="button" className="btn" id="comp-heading-btn-text">
                <span style={{ color: "gray" }}>Sort by:</span> {sortBy}
              </button>
              <button
                id="comp-heading-dropdown-btn"
                type="button"
                className="btn dropdown-toggle"
                data-toggle="dropdown"
              >
                <span className="caret"></span>
              </button>
              <ul className="dropdown-menu  dropdown-menu-right" role="menu">
                <li onClick={() => sort("desc", "Newest")}>
                  <a href="#newest">Newest</a>
                </li>
                <li onClick={() => sort("asc", "Oldest")}>
                  <a href="#oldest">Oldest</a>
                </li>
              </ul>
            </div> */}
      </div>
      <InvestmentTable
        onClickDelete={(id) => onClickDelete(id)}
        rows={data}
        onChangeStatus={(id, status) => onChangeStatus(id, status)}
        selectedRound={(round) => selectedRound(round)}
        onFilterTextChange={(e) => onFilterTextChange(e)}
        onSelectRound={(data) => onSelectRound(data)}
        onClickStart={(id) => onClickStart(id)}
      />
    </>
  );
}
